<template>
  <Modal
    :dialog="onboardingModal"
    @close="setOnboardingModal(false)"
    noPadding
    id="complete-onboarding"
  >
    <template v-slot:content>
      <p class="defaultText--text mt-5">
        {{ $t('onboarding.modal.desc') }}
      </p>
      <CardNav
        :nextText="$t('onboarding.modal.next')"
        :prevText="$t('onboarding.modal.cancel')"
        @next="completeOnboarding"
        @previous="setOnboardingModal(false)"
        class="mt-5"
        :vertical="$vuetify.breakpoint.smAndDown"
      />
    </template>
  </Modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Modal from '@/components/Shared/Modal.vue';
import CardNav from '@/components/Shared/CardNav.vue';

export default {
  name: 'CompleteOnboardingModal',
  components: {
    Modal,
    CardNav,
  },
  computed: {
    ...mapGetters('onboarding', [
      'onboardingModal',
    ]),
  },
  methods: {
    ...mapActions('onboarding', [
      'setOnboardingModal',
    ]),
    completeOnboarding() {
      this.setOnboardingModal(false);
      this.$router.push({
        name: 'Onboarding.Overview',
      });
    },
  },
};
</script>
